import { createApp } from 'vue';
import { createDesignSystem } from '@infusionsoft/design-system';
import '@infusionsoft/design-system/style.css';

import App from './App.vue';
import { auth } from './plugins/auth.js';
import { createRouter } from './router';
import { createStore } from './store';
import { i18n } from './i18n';
import performance from './shared/utils/performance.util';
import { handlePromiseRejections, handleError } from './shared/utils/error.util';
import { setupInterceptors } from './shared/utils/interceptor.util';
import { components, icons, illustrations } from './shared/utils/design-system-imports.util';
import './shared/styles/main.scss';
import { createPendo } from './analytics/pendo';
import { createSentry } from './analytics/sentry';

const app = createApp(App);
const router = createRouter();
const store = createStore();
const designSystem = createDesignSystem({
    components,
    icons,
    illustrations,
});

const pendo = createPendo({
    apiKey: import.meta.env.VITE_PENDO_API_KEY,
    enabled: import.meta.env.VITE_THIRD_PARTY_LOGGING === 'enabled',
});

const sentry = createSentry({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    org: import.meta.env.VITE_SENTRY_ORG,
    router,
    // Sentry release value being injected by the CI/CD pipeline
    release: import.meta.env.SENTRY_RELEASE || undefined,
    enabled: import.meta.env.VITE_THIRD_PARTY_LOGGING === 'enabled',
});

app.use(designSystem);
app.use(router);
app.use(auth);
app.use(store);
app.use(i18n);
app.use(pendo);
app.use(sentry);

setupInterceptors(store);

app.config.globalProperties.$number = i18n.global.n;

app.config.errorHandler = handleError;
handlePromiseRejections();

app.config.globalProperties.$pagePerformance = performance.pagePerformance;

app.mount('#app');
