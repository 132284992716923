import { ABANDONED_CART, FACTORY_INTEREST } from '../constants/operationsEvents.constants';

/**
 * Validates if the given event is a valid Operations tracking event
 * @param {string} eventName - The event name to validate
 * @returns {boolean} - true if the event name is valid, false otherwise
 */
export function operationsEventValidation(eventName) {
    const validEvents = [FACTORY_INTEREST, ABANDONED_CART];
    return validEvents.includes(eventName);
}
