// ************************************************
// TODO: As of June 30th 2024, PD stopped using Amplitude and switched to Pendo. We should audit this file and remove it if it's no longer needed.
// ************************************************

export const PAGE_VIEWED = 'Page Viewed';

// amplitude constants
export const AMPLITUDE_CONSTANTS = Object.freeze({
    PAGE_VIEWED: 'Page Viewed',
    PAGE: 'Page',
    LOGIN: 'Log in',
    CREATE_AN_ACCOUNT: 'Create an Account',
    JOIN_APP: 'Join app',
    LOGIN_AS_ANOTHER_USER: 'Log in as another user',
    CREATE_A_NEW_KEAP_ID: 'Create a new Keap ID',
    BUTTON_CLICKED: 'Button Clicked',
    JOINED_APP: 'Joined App',
    INVITE_LOGGED_IN_PAGE: 'Invite logged in',
    INVITE_NOT_LOGGED_IN_PAGE: 'Invite not logged in',
    CONTACT_LIMIT_ALERT: 'Contact limit alert',
    SUBSCRIPTION_DETAILS_CANCEL_SCHEDULED_CHANGES_MODAL_OPENED: 'Subscription Details Cancel Scheduled Changes Modal Opened',
    SUBSCRIPTION_DETAILS_CANCEL_SCHEDULED_CHANGES_MODAL_CLOSED: 'Subscription Details Cancel Scheduled Changes Modal Closed',
    SUBSCRIPTION_DETAILS_CANCEL_SCHEDULED_CHANGES_CONFIRMED: 'Subscription Details Cancel Scheduled Changes Confirmed',
    SUBSCRIPTION_DETAILS_CANCEL_SCHEDULED_CHANGES_FAILURE: 'Subscription Details Cancel Scheduled Changes Failure',
    SUBSCRIPTION_DETAILS_CANCEL_SCHEDULED_CHANGES_SUCCESS: 'Subscription Details Cancel Scheduled Changes Success',
    SUBSCRIPTION_CHANGED: 'Subscription changed',
    CHARGEBEE_PORTAL_LOADED: 'Chargebee portal loaded',
    SUBSCRIPTION_DETAILS_OPENED: 'Subscription Details Opened',
    SUBSCRIPTION_DETAILS_CLOSED: 'Subscription Details Closed',
    REQUEST_TO_CANCEL_SUCCESS: 'Request to cancel success',
    REQUEST_TO_CANCEL_FAILURE: 'Request to cancel failure',
});

// amplitude events
export const AMPLITUDE_EVENTS = Object.freeze({
    PAGE_VIEWED: AMPLITUDE_CONSTANTS.PAGE_VIEWED,
    BUTTON_CLICKED: AMPLITUDE_CONSTANTS.BUTTON_CLICKED,
    JOINED_APP: AMPLITUDE_CONSTANTS.JOINED_APP,
    CONTACT_LIMIT_ALERT: AMPLITUDE_CONSTANTS.CONTACT_LIMIT_ALERT,
    SUBSCRIPTION_DETAILS_CANCEL_SCHEDULED_CHANGES_MODAL_OPENED: AMPLITUDE_CONSTANTS.SUBSCRIPTION_DETAILS_CANCEL_SCHEDULED_CHANGES_MODAL_OPENED,
    SUBSCRIPTION_DETAILS_CANCEL_SCHEDULED_CHANGES_MODAL_CLOSED: AMPLITUDE_CONSTANTS.SUBSCRIPTION_DETAILS_CANCEL_SCHEDULED_CHANGES_MODAL_CLOSED,
    SUBSCRIPTION_DETAILS_CANCEL_SCHEDULED_CHANGES_CONFIRMED: AMPLITUDE_CONSTANTS.SUBSCRIPTION_DETAILS_CANCEL_SCHEDULED_CHANGES_CONFIRMED,
    SUBSCRIPTION_DETAILS_CANCEL_SCHEDULED_CHANGES_FAILURE: AMPLITUDE_CONSTANTS.SUBSCRIPTION_DETAILS_CANCEL_SCHEDULED_CHANGES_FAILURE,
    SUBSCRIPTION_DETAILS_CANCEL_SCHEDULED_CHANGES_SUCCESS: AMPLITUDE_CONSTANTS.SUBSCRIPTION_DETAILS_CANCEL_SCHEDULED_CHANGES_SUCCESS,
    SUBSCRIPTION_CHANGED: AMPLITUDE_CONSTANTS.SUBSCRIPTION_CHANGED,
    CHARGEBEE_PORTAL_LOADED: AMPLITUDE_CONSTANTS.CHARGEBEE_PORTAL_LOADED,
    SUBSCRIPTION_DETAILS_OPENED: AMPLITUDE_CONSTANTS.SUBSCRIPTION_DETAILS_OPENED,
    SUBSCRIPTION_DETAILS_CLOSED: AMPLITUDE_CONSTANTS.SUBSCRIPTION_DETAILS_CLOSED,
    REQUEST_TO_CANCEL_SUCCESS: AMPLITUDE_CONSTANTS.REQUEST_TO_CANCEL_SUCCESS,
    REQUEST_TO_CANCEL_FAILURE: AMPLITUDE_CONSTANTS.REQUEST_TO_CANCEL_FAILURE,
});

// amplitude event properties
export const AMPLITUDE_USER_PROPS = Object.freeze({
    PAGE: 'Page',
    BUTTON_NAME: 'Button Name',
    AUTHENTICATED: 'Authenticated',
});
