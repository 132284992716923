import { useStore } from 'vuex';
import { authGuard } from '@auth0/auth0-vue';
import { auth } from '@/plugins/auth';

const AccountsPage = () => import('@/views/AccountsPage.vue');
const AuthenticationStatusPage = () => import('@/views/AuthenticationStatusPage.vue');
const ManageAppView = () => import('@/views/ManageAppView.vue');
const ProServicesView = () => import('@/views/ProServicesView.vue');
const EditSubscriptionView = () => import('@/views/EditSubscriptionView.vue');
const EditProfilePage = () => import('@/views/EditProfilePage.vue');
const LearnPage = () => import('@/views/LearnPage.vue');
const SecuritySettingsPage = () => import('@/views/SecuritySettingsPage.vue');
const AuthorizePage = () => import('@/views/AuthorizePage.vue');
const PageNotFound = () => import('@/views/PageNotFound.vue');
const Invite = () => import('@/views/Invite.vue');
const MfaInvite = () => import('@/views/MfaInvite.vue');
const LinkInvite = () => import('@/views/LinkInvite.vue');

import { DEFAULT_ACCOUNT_PAGE_SIZE } from '@/store/accounts/state';

const routes = [
    {
        path: '/',
        name: 'home',
        redirect: '/accounts',
    },
    {
        path: '/authentication-status',
        name: 'authenticationStatus',
        component: AuthenticationStatusPage,
        meta: {
            title: 'authentication.statusTitle',
        },
        beforeEnter: authGuard,
    },
    {
        path: '/appCheck',
        name: 'loading',
        beforeEnter: async (to, from, next) => {
            const store = useStore();
            await authGuard(to);

            await store.dispatch('accounts/LOAD_ACCOUNTS', {
                pageSize: DEFAULT_ACCOUNT_PAGE_SIZE,
                searchText: null,
                orderBy: ['account.legacyId:ASC'],
                reload: true,
            });

            const appName = store.getters['accounts/getOnlyApp'];

            if (appName !== '' && appName !== undefined) {
                window.location.assign(`${import.meta.env.VITE_KEAP_URL}/dashboard?app_id=${appName}`);
            } else {
                next('/accounts');
            }
        },
    },
    {
        path: '/accounts',
        name: 'accounts',
        component: AccountsPage,
        meta: {
            title: 'navigation.apps.title',
        },
        beforeEnter: authGuard,
    },
    {
        path: '/manage-app',
        name: 'manageApp',
        component: ManageAppView,
        meta: {
            title: 'manage.app.route.name',
        },
        beforeEnter: authGuard,
    },
    {
        path: '/manage-app/services',
        name: 'services',
        component: ProServicesView,
        meta: {
            title: 'manage.services.route.name',
            hideNavbar: true,
        },
        beforeEnter: authGuard,
    },
    {
        path: '/edit-plan',
        name: 'editPlan',
        component: EditSubscriptionView,
        meta: {
            title: 'edit.subscription.title',
            hideNavbar: true,
        },
        beforeEnter: authGuard,
    },
    {
        path: '/profile',
        name: 'editProfile',
        component: EditProfilePage,
        meta: {
            title: 'navigation.keap.id.title',
        },
        beforeEnter: authGuard,
    },
    {
        path: '/learn',
        name: 'learn',
        component: LearnPage,
        meta: {
            title: 'navigation.keap.id.title',
        },
        beforeEnter: authGuard,
    },
    {
        path: '/security',
        name: 'securitySettings',
        component: SecuritySettingsPage,
        meta: {
            title: 'navigation.security.title',
        },
        beforeEnter: authGuard,
    },
    {
        path: '/authorize',
        name: 'authorize',
        component: AuthorizePage,
        meta: {
            title: 'authorize.appTitle',
            hideNavbar: true,
            isOAuth: true,
        },
        beforeEnter: authGuard,
    },
    {
        path: '/invite',
        name: 'invite',
        component: Invite,
        meta: {
            title: 'Accept your invitation',
            isPublic: true,
            isInvite: true,
            hideNavbar: true,
        },
        props: (route) => ({ ...route.query, ...route.params }),
    },
    {
        path: '/invite/link',
        name: 'linkInvite',
        component: LinkInvite,
        meta: {
            title: 'Linking account via invitation',
            isInvite: true,
            hideNavbar: true,
        },
        props: (route) => ({ ...route.query, ...route.params }),
        beforeEnter: authGuard,
    },
    {
        path: '/invite/mfa',
        name: 'mfaInvite',
        component: MfaInvite,
        meta: {
            title: 'Select an MFA Option',
            isInvite: true,
            hideNavbar: true,
        },
        props: (route) => ({ ...route.query, ...route.params }),
        beforeEnter: authGuard,
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            title: 'page.logout',
            isPublic: true,
        },
        beforeEnter: (to, from) => {
            const { logout } = auth;

            logout({
                logoutParams: {
                    service: window.location.origin,
                },
            });


            window.top.postMessage('loggedOut', `${import.meta.env.VITE_CAS_URL}`);

            if (to.query?.renew) {
                const redirectUrl = new URL(from.fullPath || '/', window.location.href);

                redirectUrl.searchParams.delete('ticket'); // ensure we don't send the JWT back in a loop

                return window.location.assign(`${import.meta.env.VITE_CAS_URL}/login?service=${encodeURIComponent(redirectUrl.toString())}&renew=true`);
            }

            if (to.query?.invite && to.query?.service) {
                return window.location.assign(to.query.service);
            }

            if (to.query?.service) {
                return window.location.assign(`${import.meta.env.VITE_CAS_URL}/logout?service=${to.query.service}`);
            }

            return window.location.assign(`${import.meta.env.VITE_CAS_URL}/logout?service=${encodeURIComponent(window.location.origin)}`);
        },
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'page.not.found',
        component: PageNotFound,
        meta: {
            title: 'page.notFound',
            isPublic: true,
        },
        beforeEnter: authGuard,
    },
];

export default routes;
