export const ADDON_TYPE = {
    ADDITIONAL_CONTACT: 'additional contact',
    LEGACY_CONTACT: 'legacy contact',
    ADDITIONAL_USER: 'additional user',
    LEGACY_USER: 'legacy user',
    KTV: 'keap text & voice',
    MESSAGE_BUNDLE: 'additional message bundle',
};

export const KSM_ADDON_TYPE = {
    CONTACT: 'contact',
    USER: 'user',
    KTV: 'minutes_and_sms_tier',
    MESSAGE_BUNDLE: 'sms_bundle',
};

export const ADDON_CHANGE_OPERATION = {
    ADD: 'ADD',
    REMOVE: 'REMOVE',
    DECREASE: 'DECREASE',
    INCREASE: 'INCREASE',
};
