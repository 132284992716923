import { components } from '@infusionsoft/design-system';

export { components };

export const icons = [
    'add',
    'alert-octagon',
    'alert-triangle',
    'arrow-down',
    'arrow-up',
    'check-circle',
    'chevron-left',
    'chevron-right',
    'external-link',
    'home',
    'info-circle',
    'refresh-cw',
    'search',
    'settings',
    'user',
    'users',
    'x',
];

export const illustrations = [
    'oops',
];
