import axios from 'axios';

import { getSentry } from '@/analytics/sentry';
import { loadLocale } from '@/i18n';
import { SUCCESS } from '@/shared/constants/loadingStatuses.constants';
import { loadFeatureFlags } from './featureFlags.js';
import { operationsEventValidation } from '../../shared/utils/operationsEvents.util.js';

const AC_BFF_URL = import.meta.env.VITE_ACCOUNT_CENTRAL_BFF_URL;

export default {
    LOAD_BOOT(context, payload) {
        return loadBoot(context, payload);
    },

    TRACK_EVENT(context, payload) {
        return trackEvent(context, payload);
    },

    TRACK_PARTNER_PRO_SERVICES_SLACK_EVENT(context, payload) {
        return trackPartnerProServicesSlackEvent(context, payload);
    },
};

const loadBoot = async ({ state, commit }, { reload = false } = {}) => {
    if (state.boot.status === SUCCESS && !reload) {
        return;
    }

    commit('LOAD_BOOT_START');

    try {
        const [featureFlags, locale] = await Promise.all([
            await loadFeatureFlags(),
            await loadLocale(),
        ]);

        commit('LOAD_BOOT_SUCCESS', { locale, featureFlags });
    } catch (e) {
        const sentry = getSentry();
        sentry.captureException(e);
        commit('LOAD_BOOT_ERROR', e);
    }
};

const trackEvent = async (_, { eventName, tenantId }) => {
    const sentry = getSentry();

    const trackEventPath = `${AC_BFF_URL}/v1/operations/trackEvent`;

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-is-tenant-id': tenantId,
        },
    };

    if (!eventName || !operationsEventValidation(eventName)) {
        sentry.captureException(new Error(`Developer error. Tracking event is not valid: ${eventName}`));
        return;
    }

    const body = {
        event_name: eventName,
    };

    try {
        await axios.post(trackEventPath, body, config);
    } catch (error) {
        sentry.captureException(error);
    }
};

const trackPartnerProServicesSlackEvent = async (_, { tenantId, customerName, customerEmail, customerPhone, customerHelpText }) => {
    const sentry = getSentry();

    const trackEventPath = `${AC_BFF_URL}/v1/operations/trackEvent/partnersProServices/learnMore`;

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-is-tenant-id': tenantId,
        },
    };

    const body = {
        name: customerName,
        email: customerEmail,
        phone: customerPhone,
        help_text: customerHelpText,
    };

    try {
        await axios.post(trackEventPath, body, config);
    } catch (error) {
        sentry.captureException(error);
        // Re-throw error to signal to the caller that the request failed
        throw error;
    }
};
