import { KSM_ADDON_TYPE, ADDON_CHANGE_OPERATION, ADDON_TYPE } from '@/shared/constants/renewalEstimate.constants';
import { i18n } from '@/i18n';
import { formatCommas } from '@/shared/utils/numbers.util';

export default {
    isSelfServiceRevoked: (state) => {
        return state.subscriptionInfo?.revokeSelfService;
    },

    isTrial: (state) => {
        const isTrial = state.subscriptionInfo?.isTrial ?? false;
        return isTrial;
    },

    subscribableContactsTiers: (state) => {
        const tiers = [{ minUnits: 0, maxUnits: 0, unitPrice: 0 }].concat(state.addonsPricing.additionalContacts.priceTiers);

        return tiers.map((tier, index) => {
            if (index < tiers.length - 1) {
                return pricingTierToTier(tier);
            }
            return {
                description: `${formatCommas(tier.minUnits)}+ ${i18n.t('edit.subscription.additional')} ${i18n.t('edit.subscription.contacts.name.plural')}`,
                quantity: Number(tier.minUnits),
                price: Number(tier.unitPrice),
            };
        });
    },

    subscribedContactsTierIndex: (state, getters) => {
        let index = 0;
        const lineItem = state.renewalEstimate.estimate.lineItems.find((item) => item.name.toLowerCase().includes(ADDON_TYPE.ADDITIONAL_CONTACT));
        if (!lineItem) {
            return index;
        }
        if (Number(lineItem.quantity) > getters.subscribableContactsTiers.at(-1).quantity) {
            return getters.subscribableContactsTiers.length - 1;
        }
        index = getters.subscribableContactsTiers.findIndex((tier) => Number(tier.quantity) === Number(lineItem.quantity));
        if (index > 0) {
            return index;
        }
        return 0;
    },

    subscribedLegacyContacts: (state) => {
        const tier = state.renewalEstimate.estimate.lineItems.find((item) => item.name.toLowerCase().includes(ADDON_TYPE.LEGACY_CONTACT));
        if (tier) {
            return {
                price: Number(tier.price),
                description: `${i18n.t('edit.subscription.legacy.contacts.description', { legacyContactCount: formatCommas(tier.quantity) })}`,
                footer: i18n.t('edit.subscription.promotionalPricingLong'),
                quantity: Number(tier.quantity),
            };
        }
        return {};
    },

    subscribableUsersTiers: (state, getters) => createTiersFromUnitPrice(
        state.addonsPricing.additionalUsers.priceTiers.at(0).unitPrice,
        state.selectedUsersTier,
        getters.subscribedUsersTierIndex,
    ),

    subscribedUsersTierIndex: (state) => {
        const tier = state.renewalEstimate.estimate.lineItems.find((item) => item.name.toLowerCase().includes(ADDON_TYPE.ADDITIONAL_USER));
        return tier ? Number(tier.quantity) : 0;
    },

    subscribedLegacyUsers: (state) => {
        const tier = state.renewalEstimate.estimate.lineItems.find((item) => item.name.toLowerCase().includes(ADDON_TYPE.LEGACY_USER));
        if (tier) {
            return {
                price: Number(tier.price),
                description: `${i18n.tc('edit.subscription.legacy.users.description', Number(tier.quantity), { legacyUserCount: tier.quantity })}`,
                footer: i18n.t('edit.subscription.promotionalPricingLong'),
                quantity: Number(tier.quantity),
            };
        }
        return {};
    },

    subscribableKtvTiers: (state) => {
        const zeroTier = [{
            quantity: 0, price: 0, description: i18n.t('edit.subscription.ktv.tier.description', { minutes: 0, messages: 0 }), minutes: 0, messages: 0,
        }];

        return zeroTier.concat(state.addonsPricing.keapTextAndVoice.metadata.tiers.map((tier) => ({
            ...pricingTierToTier(
                state.addonsPricing.keapTextAndVoice.priceTiers.find((pricingTier) => pricingTier.maxUnits === tier.addonQuantity),
            ),
            description: i18n.t('edit.subscription.ktv.tier.description', {
                minutes: formatCommas(tier.includedMinutes),
                messages: formatCommas(tier.includedMessages),
            }),
            minutes: tier.includedMinutes,
            messages: tier.includedMessages,
        })));
    },

    subscribedKtvTierIndex: (state) => {
        const lineItem = state.renewalEstimate.estimate.lineItems.find((item) => item.name.toLowerCase().includes(ADDON_TYPE.KTV));
        if (lineItem) {
            return Number(lineItem.quantity);
        }
        return 0;
    },

    subscribableMessageBundleTiers: (state, getters) => createTiersFromUnitPrice(
        state.addonsPricing.additionalSms.priceTiers.at(0).unitPrice,
        state.selectedMessageBundleTier,
        getters.subscribedMessageBundleTierIndex,
    ).map((tier) => ({
        ...tier,
        description: i18n.t('subscription.details.sms.bundle.description', {
            bundleQuantity: formatCommas(tier.quantity * 500),
        }),
    })),

    subscribedMessageBundleTierIndex: (state) => {
        const tier = state.renewalEstimate.estimate.lineItems.find((item) => item.name.toLowerCase().includes(ADDON_TYPE.MESSAGE_BUNDLE));
        return tier ? Number(tier.quantity) : 0;
    },

    tierChanges: (state, getters) => {
        const changes = [];

        const subscribedContactsTier = getters.subscribableContactsTiers.at(getters.subscribedContactsTierIndex);
        const selectedContactsTier = getters.subscribableContactsTiers.at(state.selectedContactsTier);
        if (subscribedContactsTier.quantity !== selectedContactsTier.quantity) {
            changes.push({
                addon_type: KSM_ADDON_TYPE.CONTACT,
                quantity: selectedContactsTier.quantity,
                operation: calculateChangeOperation(subscribedContactsTier, selectedContactsTier),
            });
        }

        const subscribedUsersTier = { quantity: getters.subscribedUsersTierIndex };
        const selectedUsersTier = getters.subscribableUsersTiers.at(state.selectedUsersTier);
        if (subscribedUsersTier.quantity !== selectedUsersTier.quantity) {
            changes.push({
                addon_type: KSM_ADDON_TYPE.USER,
                quantity: selectedUsersTier.quantity,
                operation: calculateChangeOperation(subscribedUsersTier, selectedUsersTier),
            });
        }

        const subscribedKtvTier = getters.subscribableKtvTiers.at(getters.subscribedKtvTierIndex);
        const selectedKtvTier = getters.subscribableKtvTiers.at(state.selectedKtvTier);
        if (subscribedKtvTier.quantity !== selectedKtvTier.quantity) {
            changes.push({
                addon_type: KSM_ADDON_TYPE.KTV,
                quantity: selectedKtvTier.quantity,
                operation: calculateChangeOperation(subscribedKtvTier, selectedKtvTier),
            });
        }

        const subscribedMessageBundleTier = getters.subscribableMessageBundleTiers.at(getters.subscribedMessageBundleTierIndex);
        const selectedMessageBundleTier = getters.subscribableMessageBundleTiers.at(state.selectedMessageBundleTier);
        if (subscribedMessageBundleTier.quantity !== selectedMessageBundleTier.quantity) {
            changes.push({
                addon_type: KSM_ADDON_TYPE.MESSAGE_BUNDLE,
                quantity: selectedMessageBundleTier.quantity,
                operation: calculateChangeOperation(subscribedMessageBundleTier, selectedMessageBundleTier),
            });
        }

        return changes;
    },

    userHasManageBillingPermission: (state) => {
        return state?.hasManageBillingPermission;
    },

    isMonthlySubscription: (state) => {
        return state.subscriptionInfo?.periodUnit?.toLowerCase() === 'month';
    },
};

function calculateChangeOperation(subscribed, selected) {
    if (selected.quantity === 0) {
        return ADDON_CHANGE_OPERATION.REMOVE;
    } else if (subscribed.quantity === 0) {
        return ADDON_CHANGE_OPERATION.ADD;
    } else if (selected.quantity < subscribed.quantity) {
        return ADDON_CHANGE_OPERATION.DECREASE;
    }
    return ADDON_CHANGE_OPERATION.INCREASE;
}

function pricingTierToTier(tier) {
    return {
        quantity: tier.maxUnits,
        price: tier.unitPrice,
    };
}

function createTiersFromUnitPrice(unitPrice, selectedTier, subscribedTier) {
    const createTier = (index) => ({ quantity: index, price: index * unitPrice });

    const tiers = selectedTier > 0 ? new Array(selectedTier - 1) : [];
    if (selectedTier > 0) {
        tiers.push(createTier(selectedTier - 1));
    }
    tiers.push(createTier(selectedTier));
    tiers.push(createTier(selectedTier + 1));
    tiers[subscribedTier] = createTier(subscribedTier);
    return tiers;
}
