<template>
    <div class="top-bar">
        <header>
            <router-link class="home" to="/">
                <DsLogo
                    icon-only
                    class="logo"
                />
                <div class="title">
                    {{ $t('app.title') }}
                </div>
            </router-link>
            <div class="user-logout">
                <div class="user-info">
                    <div class="name">
                        <FullName />
                    </div>
                    <div class="username">
                        {{ user?.preferred_username }}
                    </div>
                </div>
                <DsTextButton
                    as="a"
                    href="logout"
                    data-qa="user-logout-button"
                >
                    {{ $t('action.logout') }}
                </DsTextButton>
            </div>
        </header>
        <nav>
            <DsTabLinks :tabs="tabs" data-qa="tabs" class="tabs" />
        </nav>
    </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { useAuth0 } from '@auth0/auth0-vue';
import { computed } from 'vue';
import { useStore } from 'vuex';

import {
    DsTextButton,
    DsTabLinks,
    DsLogo,
} from '@infusionsoft/design-system';
import FullName from '@/components/FullName.vue';
import {
    FF_PLAT_7490_NAV_APPS,
    FF_PLAT_7487_NAV_LEARN,
    FF_PLAT_7488_NAV_KEAP_ID,
    FF_PLAT_7489_NAV_SECURITY,
} from '@/shared/constants/featureFlag.constants';

export default {
    components: {
        DsTextButton,
        DsTabLinks,
        DsLogo,
        FullName,
    },

    setup() {
        const { t } = useI18n();
        const store = useStore();

        const showNavApps = computed(() => store.state.global.featureFlags[FF_PLAT_7490_NAV_APPS]);
        const showNavLearn = computed(() => store.state.global.featureFlags[FF_PLAT_7487_NAV_LEARN]);
        const showNavKeapId = computed(() => store.state.global.featureFlags[FF_PLAT_7488_NAV_KEAP_ID]);
        const showNavSecurity = computed(() => store.state.global.featureFlags[FF_PLAT_7489_NAV_SECURITY]);

        const tabs = computed(() => {
            const resultList = [];

            if (showNavApps.value) {
                resultList.push({ link: '/accounts', label: t('navigation.apps.title') });
            } else {
                resultList.push({ link: '/accounts', label: t('accounts.accountsTitle') });
            }

            if (showNavLearn.value) {
                resultList.push({ link: '/learn', label: t('navigation.learn.title') });
            }

            if (showNavKeapId.value) {
                resultList.push({ link: '/profile', label: t('navigation.keap.id.title') });
            } else {
                resultList.push({ link: '/profile', label: t('profile.title') });
            }

            if (showNavSecurity.value) {
                resultList.push({ link: '/security', label: t('navigation.security.title') });
            } else {
                resultList.push({ link: '/security', label: t('security.settingsTitle') });
            }

            return resultList;
        });

        const auth = useAuth0();

        return {
            tabs,
            user: auth.user,
        };
    },
};


</script>

<style lang="scss" scoped>
    .top-bar {
        position: sticky;
        top: 0;
        background-color: $color-paper;
        z-index: 1;
        width: 100%;
        max-width: 100%;
    }

    header {
        width: 100%;
        border-bottom: 1px solid $color-ink-200;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: $spacing-100;
        max-width: 100%;
    }

    nav {
        @include container;
        background-color: $color-paper;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }

    .tabs {
        border-bottom: solid px-to-rem(1px) $color-ink-200;
        padding-top: $spacing-200;
    }

    .home {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: $color-ink;

        &:hover, &:visited, &:active, &:link {
            color: $color-ink;
            text-decoration: none;
        }

        .title {
            font-size: $font-size-md;
            padding: $spacing-100;
            white-space: nowrap;

            @media(max-width: 450px) {
                display: none;
            }
        }

        .logo {
            --logo-width: #{$font-size-md};
            width: #{$font-size-md};
            margin: $spacing-100;
        }
    }

    .user-info {
        display: grid;
        margin-right: $spacing-100;

        .name {
            font-size: $font-size-sm;
            text-align: right;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-height: 20px;
        }

        .username {
            font-size: $font-size-xs;
            color: $color-ink-800;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-height: 20px;
            @media(max-width: 330px) {
                display: none;
            }
        }
    }

    .user-logout {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0 $spacing-100;

        a {
            white-space: nowrap;
        }
    }
</style>
